import React from "react"
import { kebabCase } from "lodash"
import { Container, Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
import AnimationLink from "../../components/nav/AnimationLink"
import { Header1 } from "../../elements"
import SEO from "../../components/seo"
import TransitionContext from "../../context/TransitionContext"

const TagsPage = ({
  data: {
    allMdx: { group },
    site: {
      siteMetadata: { title },
    },
  },
  location,
}) => (
  <TransitionContext location={location}>
    <section className="section">
      <SEO title={`Tags | ${title}`} />
      <Container fluid className="pagemargintop pagemarginbtm innerwrap">
        <Row justify="center" align="center">
          <Col>
            <Header1 lineOne="All Tags" lineTwo="Click a tag to view more." />
          </Col>
        </Row>
        <Row justify="center" align="center">
          <Col xs={12}>
            <ul className="taglist">
              {group.map(tag => (
                <li key={tag.fieldValue}>
                  <AnimationLink
                    ariaLabel={tag.fieldValue}
                    to={`/tags/${kebabCase(tag.fieldValue)}/`}
                    state={{ fromTagLink: true }}
                  >
                    {tag.fieldValue} ({tag.totalCount})
                  </AnimationLink>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row align="center" justify="center">
          <Col xs="content">
            <AnimationLink
              ariaLabel={`Link to recent posts`}
              to={`/blog`}
              state={{ fromTagLink: true }}
              className="alltags"
            >
              Back to Recent Posts
            </AnimationLink>
          </Col>
        </Row>
      </Container>
    </section>
  </TransitionContext>
)

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
